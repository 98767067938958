import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from "@mui/material/AppBar";
import {useAppStore} from "../../Store/appStore";
import {Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";

const AppBar = styled(MuiAppBar,
    {})(({theme}) => ({
    zIndex: theme.zIndex.drawer + 1
}))
export default function NavBar() {

    const navigate = useNavigate()

    const updateOpen = useAppStore((state) => state.updateOpen)
    const dopen = useAppStore((state) => state.dopen)

    const handleLogout = () => {
        localStorage.clear()
        navigate("/")
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                        onClick={() => updateOpen(!dopen)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Select Baby Names Admin Panel
                    </Typography>
                    <Button color="inherit" onClick={handleLogout}>Logout</Button>
                </Toolbar>
            </AppBar>
        </Box>
    );
}