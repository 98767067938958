import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {editName} from "../../../../Services/name.service";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditCategoryFullScreenDialog({categoryId, oldCategoryDetails, isEditCategoryDialogOpen, handleEditCategoryDialogClickClose}) {
    const [nameDetails, setNameDetails] = useState({
        name: '',
        meaning: '',
        description: '',
        gender: '',
        religion: '',
        nameOfGod: '',
        numerologyNumber: '',
        rashiName: '',
        zodiac: ''
    });

    // Update the state when oldNameDetails changes
    useEffect(() => {
        if (oldCategoryDetails) {
            const { name, meaning, description, gender, religion, nameOfGod, numerologyNumber, rashiName, zodiac } = oldCategoryDetails;
            setNameDetails({
                name: name || '',
                meaning: meaning || '',
                description: description || '',
                gender: gender || '',
                religion: religion || '',
                nameOfGod: nameOfGod || '',
                numerologyNumber: numerologyNumber || '',
                rashiName: rashiName || '',
                zodiac: zodiac || ''
            });
        }
    }, [oldCategoryDetails]);
    const handleNameEdit = () => {
        if (nameDetails.name.trim() === "") {
            toast.info("Name is required", {
                position: "bottom-right",
            });
            return;
        }
        if (nameDetails.meaning.trim() === "") {
            toast.info("Meaning is required", {
                position: "bottom-right",
            });
            return;
        }
        if (nameDetails.description.trim() === "") {
            toast.info("Description is required", {
                position: "bottom-right",
            });
            return;
        }
        if (nameDetails.gender.trim() === "") {
            toast.info("Gender is required", {
                position: "bottom-right",
            });
            return;
        }
        if (nameDetails.religion.trim() === "") {
            toast.info("Religion is required", {
                position: "bottom-right",
            });
            return;
        }
        if (nameDetails.nameOfGod.trim() === "") {
            toast.info("Name of God is required", {
                position: "bottom-right",
            });
            return;
        }
        if (nameDetails.numerologyNumber.trim() === "") {
            toast.info("Numerology Number is required", {
                position: "bottom-right",
            });
            return;
        }
        if (nameDetails.rashiName.trim() === "") {
            toast.info("Rashi is required", {
                position: "bottom-right",
            });
            return;
        }
        if (nameDetails.zodiac.trim() === "") {
            toast.info("Zodiac Sign is required", {
                position: "bottom-right",
            });
            return;
        }
        editName(nameDetails, categoryId).then(editNameResponse => {
            console.log(JSON.stringify(editNameResponse))
            if (editNameResponse.status === 202) {
                toast.success("Name Edited Successfully")
                handleEditCategoryDialogClickClose()
            }
        })
        console.log(`Name details are ${JSON.stringify(nameDetails)}`);
    }
    return (
        <React.Fragment>
            <Dialog
                fullScreen
                open={isEditCategoryDialogOpen}
                onClose={handleEditCategoryDialogClickClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleEditCategoryDialogClickClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Add Name
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleNameEdit}>
                            Save
                        </Button>
                    </Toolbar>
                </AppBar>
                <List>
                    <ListItem>
                        <TextField
                            required
                            id="outlined-required"
                            label="Name"
                            value={nameDetails.name}
                            onChange={(event) => {
                                setNameDetails({
                                    ...nameDetails,
                                    name: event.target.value,
                                });
                            }}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <TextField
                            required
                            id="outlined-required"
                            label="Meaning"
                            value={nameDetails.meaning}
                            onChange={(event) => {
                                setNameDetails({
                                    ...nameDetails,
                                    meaning: event.target.value,
                                });
                            }}
                            style={{ width: '80%' }}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <TextField
                            required
                            id="outlined-required"
                            label="Description"
                            multiline
                            maxRows={4}
                            value={nameDetails.description}
                            onChange={(event) => {
                                setNameDetails({
                                    ...nameDetails,
                                    description: event.target.value,
                                });
                            }}
                            style={{ width: '80%' }}
                        />
                    </ListItem>
                    <Divider />
                    <FormControl required style={{
                        width: '10%',
                        marginLeft: "15px",
                        marginTop: "15px",
                        marginBottom: "15px"
                    }}>
                        <InputLabel id="demo-simple-select-required-label">Gender</InputLabel>
                        <Select
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={nameDetails.gender}
                            onChange={(event) => {
                                setNameDetails({
                                    ...nameDetails,
                                    gender: event.target.value,
                                });
                            }}
                            label="Gender *"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={"Male"}>Male</MenuItem>
                            <MenuItem value={"Female"}>Female</MenuItem>
                            <MenuItem value={"Others"}>Others</MenuItem>
                        </Select>
                    </FormControl>
                    <Divider />
                    <ListItem>
                        <TextField
                            required
                            id="outlined-required"
                            label="Religion"
                            value={nameDetails.religion}
                            onChange={(event) => {
                                setNameDetails({
                                    ...nameDetails,
                                    religion: event.target.value,
                                });
                            }}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <TextField
                            required
                            id="outlined-required"
                            label="Name Of God"
                            value={nameDetails.nameOfGod}
                            onChange={(event) => {
                                setNameDetails({
                                    ...nameDetails,
                                    nameOfGod: event.target.value,
                                });
                            }}
                            style={{ width: '30%' }}
                        />
                    </ListItem>
                    <Divider />
                    <FormControl required style={{
                        width: '15%',
                        marginLeft: "15px",
                        marginTop: "15px",
                        marginBottom: "15px"
                    }}>
                        <InputLabel id="numerology-input-id">Numerology Number</InputLabel>
                        <Select
                            labelId="numerology-input-select-required-label"
                            id="numerology-input-select-required"
                            value={nameDetails.numerologyNumber}
                            onChange={(event) => {
                                setNameDetails({
                                    ...nameDetails,
                                    numerologyNumber: event.target.value,
                                });
                            }}
                            label="Numerology Number *"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={"1"}>1</MenuItem>
                            <MenuItem value={"2"}>2</MenuItem>
                            <MenuItem value={"3"}>3</MenuItem>
                            <MenuItem value={"4"}>4</MenuItem>
                            <MenuItem value={"5"}>5</MenuItem>
                            <MenuItem value={"6"}>6</MenuItem>
                            <MenuItem value={"7"}>7</MenuItem>
                            <MenuItem value={"8"}>8</MenuItem>
                            <MenuItem value={"9"}>9</MenuItem>
                        </Select>
                    </FormControl>
                    <Divider />
                    <FormControl required style={{
                        width: '10%',
                        marginLeft: "15px",
                        marginTop: "15px",
                        marginBottom: "15px"
                    }}>
                        <InputLabel id="rashi-input-id">Rashi</InputLabel>
                        <Select
                            labelId="rashi-input-select-required-label"
                            id="rashi-input-select-required"
                            label="Rashi *"
                            value={nameDetails.rashiName}
                            onChange={(event) => {
                                setNameDetails({
                                    ...nameDetails,
                                    rashiName: event.target.value,
                                });
                            }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={"Mesha"}>Mesha</MenuItem>
                            <MenuItem value={"Vrishabh"}>Vrishabh</MenuItem>
                            <MenuItem value={"Mithun"}>Mithun</MenuItem>
                            <MenuItem value={"Karka"}>Karka</MenuItem>
                            <MenuItem value={"Sinh"}>Sinh</MenuItem>
                            <MenuItem value={"Kanya"}>Kanya</MenuItem>
                            <MenuItem value={"Tula"}>Tula</MenuItem>
                            <MenuItem value={"Vruschik"}>Vruschik</MenuItem>
                            <MenuItem value={"Dhanu"}>Dhanu</MenuItem>
                            <MenuItem value={"Makar"}>Makar</MenuItem>
                            <MenuItem value={"Kumbh"}>Kumbh</MenuItem>
                            <MenuItem value={"Meena"}>Meena</MenuItem>
                        </Select>
                    </FormControl>
                    <Divider />
                    <FormControl required style={{
                        width: '10%',
                        marginLeft: "15px",
                        marginTop: "15px",
                        marginBottom: "15px"
                    }}>
                        <InputLabel id="zodiac-input-id">Zodiac Sign</InputLabel>
                        <Select
                            labelId="zodiac-input-select-required-label"
                            id="zodiac-input-select-required"
                            value={nameDetails.zodiac}
                            onChange={(event) => {
                                setNameDetails({
                                    ...nameDetails,
                                    zodiac: event.target.value,
                                });
                            }}
                            label="Zodiac Sign *"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={"Mesha"}>Mesha</MenuItem>
                            <MenuItem value={"Vrishabh"}>Vrishabh</MenuItem>
                            <MenuItem value={"Mithun"}>Mithun</MenuItem>
                            <MenuItem value={"Karka"}>Karka</MenuItem>
                            <MenuItem value={"Sinh"}>Sinh</MenuItem>
                            <MenuItem value={"Kanya"}>Kanya</MenuItem>
                            <MenuItem value={"Tula"}>Tula</MenuItem>
                            <MenuItem value={"Vruschik"}>Vruschik</MenuItem>
                            <MenuItem value={"Dhanu"}>Dhanu</MenuItem>
                            <MenuItem value={"Makar"}>Makar</MenuItem>
                            <MenuItem value={"Kumbh"}>Kumbh</MenuItem>
                            <MenuItem value={"Meena"}>Meena</MenuItem>
                        </Select>
                    </FormControl>
                </List>
            </Dialog>
        </React.Fragment>
    );
}