import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {getIsUserLoggedIn, getUserId, getUserToken} from "../../../Store/AuthenticationStore";
import Sidenav from "../Sidenav";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import NavBar from "../NavBar";
import AllNamesDataTable from "../Name/Components/AllNamesDataTable";
import AllCategoriesDataTable from "./Components/AllCategoriesDataTable";

const AllCategories = () => {
    return (
        <>
            <NavBar/>
            <Box height={30}/>
            <Box sx={{display: "flex"}}>
                <Sidenav/>
                <Box component="main" sx={{flexGrow: 1, p: 3}}>
                    <h1>
                        AllCategories
                    </h1>
                    <AllCategoriesDataTable/>
                </Box>
            </Box>
        </>
    );
};

export default AllCategories;