import Box from "@mui/material/Box";
import * as React from "react";
import Sidenav from "./Sidenav";
import NavBar from "./NavBar";

const TermsAndConditions = () => {
    return (
        <>
            <NavBar/>
            <Box height={30}/>
            <Box sx={{display: "flex"}}>
                <Sidenav/>

                <Box component="main" sx={{flexGrow: 1, p: 3}}>
                    <h1>
                        TermsAndConditions
                    </h1>
                </Box>
            </Box>
        </>
    );
};

export default TermsAndConditions;