export const saveUserId = (userId) => {
    localStorage.setItem("userId", userId);
};

export const saveUserToken = (userToken) => {
    localStorage.setItem("userToken", userToken);
};

export const saveIsUserLoggedIn = (isUserLoggedIn) => {
    localStorage.setItem("isUserLoggedIn", isUserLoggedIn);
};

export const getUserId = () => {
    const userId = localStorage.getItem("userId");
    if (userId === null) {
        return "";
    } else {
        return userId;
    }
};

export const getUserToken = () => {
    const userToken = localStorage.getItem("userToken");
    if (userToken === null) {
        return ""
    } else {
        return userToken
    }
};

export const getIsUserLoggedIn = () => {
    const isUserLoggedIn = localStorage.getItem("isUserLoggedIn")
    if (isUserLoggedIn === null) {
        return ""
    } else {
        return isUserLoggedIn
    }
};