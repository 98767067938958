import {baseURLAxios, privateAxios} from "./axios.service";
import {getUserId, getUserToken} from "../Store/AuthenticationStore";
import {toast} from "react-toastify";

export const getAllNames = () => {
    return privateAxios.get(`/name/all/${getUserId()}`)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Server responded with error data:', error.response.data);
                console.error('Status code:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received. Request details:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error setting up the request:', error.message);
            }

            // Propagate the error to the calling code if needed
            throw error;
        });
};

export const addName = (nameData) => {
    return privateAxios.post(`/name/create`, nameData)
        .then((response) => {
            return response
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Server responded with error data:', error.response.data);
                console.error('Status code:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received. Request details:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error setting up the request:', error.message);
            }

            // Propagate the error to the calling code if needed
            throw error;
        });
};

export const editName = (nameData, nameId) => {
    return privateAxios.put(`/name/edit/${nameId}`, nameData)
        .then((response) => {
            toast.success(`Response code is ${response.status}`)
            return response
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Server responded with error data:', error.response.data);
                console.error('Status code:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received. Request details:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error setting up the request:', error.message);
            }

            // Propagate the error to the calling code if needed
            throw error;
        });
};