import React, {useState} from 'react';
import "./OTP.css"
import {Button} from "@mui/material";
import { toast } from 'react-toastify';
import {MuiOtpInput} from "mui-one-time-password-input";
import {useLocation, useNavigate} from "react-router-dom";
import {validateOTPRequest} from "../../../Services/authentication.service.js";
import {saveIsUserLoggedIn, saveUserId, saveUserToken} from "../../../Store/AuthenticationStore";
export function matchIsNumeric(text) {
    const isNumber = typeof text === 'number';
    return (isNumber || (text !== '')) && !isNaN(Number(text));
}
const OTP = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [otpDetail, setValues] = useState({
        otp: '',
    });
    const validateOTP = () => {
        if (otpDetail.otp === "" && otpDetail.otp.length === 4) {
            toast.error("OTP is required");
        } else {
            toast.success(`User id is ${location}`)
            validateOTPRequest(location.state.userId, otpDetail.otp)
                .then(verifyOTPResponse => {
                    saveUserId(verifyOTPResponse["data"]["id"].toString())
                    saveUserToken(verifyOTPResponse["data"]["jwttoken"].toString())
                    saveIsUserLoggedIn("true")
                    toast.success("User created successfully !!");
                    navigate('/home', {replace: true})
                })
                .catch(error => {
                    //error handler
                    console.log(error)
                    toast.error("Error in creating user ! Try again")
                })
                .finally(() => {
                    // TODO: Set loading false here
                })
            // Add your login logic here
        }
    }
    const handleOTPChange = (prop) => (value) => {
        setValues({ ...otpDetail, [prop]: value });
    };

    const handleOTPComplete = (value) => {
        toast.success("OTP entered is " + value, {
            position: toast.POSITION.TOP_CENTER
        })
        if (value.length === 4) {
            validateOTP()
        }
    }

    const validateChar = (value, index) => {
        return matchIsNumeric(value)
    }


    return (
        <div className="otp-container">
            <h1 className="title-style">
                OTP Page
            </h1>

            <MuiOtpInput
                value={otpDetail.otp}
                style={{ marginBottom: '20px' }}
                length={4}
                onComplete={handleOTPComplete}
                autoFocus
                validateChar={validateChar}
                onChange={(value) => handleOTPChange('otp')(value)}  // Pass the value directly
            />

            <Button
                variant="contained"
                color="primary"
                className="otp-button"
                onClick={validateOTP}
            >
                Validate OTP
            </Button>
        </div>
    );
};

export default OTP;