import axios from "axios";
import { BASE_URL } from "./helper.service";
import {getUserToken} from "../Store/AuthenticationStore";

export const baseURLAxios = axios.create({
    baseURL: BASE_URL,
});

export const privateAxios = axios.create({
    baseURL: BASE_URL,
});

privateAxios.interceptors.request.use(
    (config) => {
        //request mein modification karenge
        const token = getUserToken();
        if (token) {
            console.log(config.headers);
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);
