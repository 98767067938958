import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Home from "../Admin/Home/Home";
import OTP from "../Authentication/OTP/OTP";
import MobileNumber from "../Authentication/MobileNumber/MobileNumber";
import {getIsUserLoggedIn} from "../../Store/AuthenticationStore";
import AdminRoot from "../Admin/AdminRoot";
import AllCategories from "../Admin/Category/AllCategories";
import AllNames from "../Admin/Name/AllNames";
import AllSubCategories from "../Admin/SubCategory/AllSubCategories";
import PrivacyPolicy from "../Admin/PrivacyPolicy";
import TermsAndConditions from "../Admin/TermsAndConditions";
import Settings from "../Admin/Settings";
import AddNames from "../Admin/Name/AddNames";

function Root() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={getIsUserLoggedIn() === "true" ? <Home/> : <Navigate replace to={"/mobile_number"} />}
                    />
                    <Route path="/home" element={<Home/>}/>
                    <Route path="/all_names" element={<AllNames/>}/>
                    <Route path="/add_names" element={<AddNames/>}/>
                    <Route path="/all_categories" element={<AllCategories/>}/>
                    <Route path="/all_subcategories" element={<AllSubCategories/>}/>
                    <Route path="/privacy_policy" element={<PrivacyPolicy/>}/>
                    <Route path="/terms_conditions" element={<TermsAndConditions/>}/>
                    <Route path="/settings" element={<Settings/>}/>
                    <Route path="/admin_root" element={<AdminRoot/>}/>
                    <Route path="/otp" element={<OTP/>}/>
                    <Route path="/mobile_number" element={<MobileNumber/>}/>
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default Root;
