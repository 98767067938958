import Sidenav from "../Sidenav";
import Box from "@mui/material/Box";
import * as React from "react";
import NavBar from "../NavBar";
import {useState} from "react";
import {
    Button, Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField
} from "@mui/material";
import {styled} from "@mui/material/styles";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import * as XLSX from "xlsx";
import {toast} from "react-toastify";
import {DataGrid} from "@mui/x-data-grid";
import {read, utils} from "xlsx";
import clipboard from "clipboardy";
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const AddNames = () => {
    const [data, setData] = useState([]);
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const [filter, setFilter] = useState('');
    const isFileValid = (fileName) => {
        const allowedExtensions = ['xlsx', 'xls', 'csv'];
        const extension = fileName.split('.').pop().toLowerCase();
        return allowedExtensions.includes(extension);
    };
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file && isFileValid(file.name)) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const workbook = read(e.target.result, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const sheetData = utils.sheet_to_json(sheet, { header: 1 });

                setData(sheetData);
            };

            reader.readAsBinaryString(file);
        } else {
            toast.error("Please upload a valid Excel or CSV file.")
        }
    };

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const filteredData = data.slice(1).filter((row) => {
        return row.some((cell) =>
            String(cell).toLowerCase().includes(filter.toLowerCase())
        );
    });

    const sortedData = orderBy
        ? [...filteredData].sort((a, b) => {
            const aValue = a[data[0].indexOf(orderBy)];
            const bValue = b[data[0].indexOf(orderBy)];

            if (order === 'asc') {
                return aValue > bValue ? 1 : -1;
            } else {
                return aValue < bValue ? 1 : -1;
            }
        })
        : filteredData;
    const handleCopyToClipboard = () => {
        const jsonData = JSON.stringify(
            data.slice(1).map((row) =>
                row.reduce((acc, cell, cellIndex) => {
                    const header = data[0][cellIndex];
                    acc[header] = cell;
                    return acc;
                }, {})
            ),
            null,
            2
        );

        clipboard.write(jsonData);
    };
    return (
        <>
            <NavBar/>
            <Box height={30}/>
            <Box sx={{display: "flex"}}>
                <Sidenav/>
                <Box component="main" sx={{flexGrow: 1, p: 3}}>
                    <h1>
                        <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                            Upload file
                            <VisuallyHiddenInput type="file" onChange={handleFileUpload} />
                        </Button>
                        <Button variant="contained" onClick={handleCopyToClipboard}>Contained</Button>
                    </h1>
                    <TextField
                        label="Filter"
                        variant="outlined"
                        onChange={handleFilterChange}
                        style={{ margin: '10px 0' }}
                    />
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {data.length > 0 &&
                                        data[0].map((header, index) => (
                                            <TableCell key={index}>
                                                <TableSortLabel
                                                    active={orderBy === header}
                                                    direction={orderBy === header ? order : 'asc'}
                                                    onClick={() => handleSort(header)}
                                                >
                                                    {header}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedData.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {row.map((cell, cellIndex) => (
                                            <TableCell key={cellIndex}>{cell}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </>
    );
};

export default AddNames;