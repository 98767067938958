import Sidenav from "../Sidenav";
import Box from "@mui/material/Box";
import * as React from "react";
import NavBar from "../NavBar";
import AllNamesDataTable from "./Components/AllNamesDataTable";
const AllNames = () => {
    return (
        <>
            <NavBar/>
            <Box height={30}/>
            <Box sx={{display: "flex"}}>
                <Sidenav/>

                <Box component="main" sx={{flexGrow: 1, p: 3}}>
                    <h1>
                        AllNames
                    </h1>
                    <AllNamesDataTable/>
                </Box>
            </Box>
        </>
    );
};

export default AllNames;