import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {useEffect, useState} from "react";
import ExpandableSubCategoryDataTable from "./ExpandableSubCategoryDataTable";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CategoryDetailsFullScreenDialog({oldCategoryDetails, isCategoryDetailsDialogOpen, handleCategoryDetailsDialogClickClose}) {
    const [categoryDetails, setCategoryDetails] = useState({
        categoryName: '',
        categoryImage: '',
        subCategoryList: []
    });
    // // Update the state when oldNameDetails changes
    useEffect(() => {
        if (oldCategoryDetails) {
            const { categoryName, categoryImage, subCategoryList } = oldCategoryDetails;
            setCategoryDetails({
                categoryName: categoryName || '',
                categoryImage: categoryImage || '',
                subCategoryList: subCategoryList || ''
            });
        }
    }, [oldCategoryDetails]);
    return (
        <React.Fragment>
            <Dialog
                fullScreen
                open={isCategoryDetailsDialogOpen}
                onClose={handleCategoryDetailsDialogClickClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCategoryDetailsDialogClickClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Category Details
                        </Typography>
                    </Toolbar>
                </AppBar>
                <List>
                    <ListItem>
                        <h1>Category Name: {categoryDetails["categoryName"]}</h1>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <div>
                            <h2>{categoryDetails.categoryName}</h2>
                            <img src={categoryDetails.categoryImage} alt={categoryDetails.categoryName} style={{maxWidth: '100%'}}/>
                        </div>
                    </ListItem>
                    <Divider/>
                    <ExpandableSubCategoryDataTable subCategories={categoryDetails.subCategoryList}/>
                </List>
            </Dialog>
        </React.Fragment>
    );
}