import {baseURLAxios} from "./axios.service";
import {toast} from "react-toastify";

export const sendOTP = (otpData) => {
    toast.success("It is in send otp services")
    toast.success(`OTP data value is ${otpData.trim()}`)
    return baseURLAxios.post(`/admin/send_otp?mobileNumber=${otpData}`)
        .then((response) => response.data)
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Server responded with error data:', error.response.data);
                console.error('Status code:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received. Request details:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error setting up the request:', error.message);
            }

            // Propagate the error to the calling code if needed
            throw error;
        });
};

export const validateOTPRequest = (userId, otp) => {
    toast.success("It is in send otp services")
    return baseURLAxios.post(`/admin/verify_otp/${userId}/${otp}`)
        .then((response) => response.data)
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Server responded with error data:', error.response.data);
                console.error('Status code:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received. Request details:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error setting up the request:', error.message);
            }

            // Propagate the error to the calling code if needed
            throw error;
        });
};