import './App.css';
import {ToastContainer} from "react-toastify";
import Root from "./Components/Root/Root";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
      <>
          <Root/>
          <ToastContainer />
      </>
  );
}

export default App;
