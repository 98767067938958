import Sidenav from "./Sidenav";
import Box from "@mui/material/Box";

const AdminRoot = () => {
    return (
        <>
            <Sidenav/>
        </>
    )
}

export default AdminRoot