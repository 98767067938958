import React, {useState} from 'react';
import "./MobileNumber.css"
import {Button} from "@mui/material";
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import { toast } from 'react-toastify';
import {sendOTP} from "../../../Services/authentication.service.js";
import {useNavigate} from "react-router-dom";

const MobileNumber = () => {
    const navigate = useNavigate();
    const [mobileNumberDetail, setData] = useState({
        mobileNumber: '',
    });
    const sendOTPRequest = () => {
        if (mobileNumberDetail.mobileNumber === "") {
            toast.error("Mobile Number is required");
        } else if (! matchIsValidTel(mobileNumberDetail.mobileNumber)) {
            toast.error("Invalid Mobile Number");
        } else {
            toast.success("It is else mobile number")
            sendOTP(mobileNumberDetail.mobileNumber.slice(3).replace(/\s/g, ""))
                .then(sendOTPResponse => {
                    //success handler
                    console.log(`this is user data ${sendOTPResponse["data"]["userId"]}`)
                    toast.success("User created successfully !!");
                    navigate('/otp', {replace: true, state: {userId: sendOTPResponse["data"]["userId"]}})
                })
                .catch(error => {
                    //error handler
                    console.log(error)
                    toast.error("Error in creating user ! Try again")
                })
                .finally(() => {
                    // TODO: Set loading false here
                })
            // Add your login logic here
        }
    }
    const handleMobileNumberChange = (prop) => (value) => {
        setData(
            {
                ...mobileNumberDetail,
                [prop]: value
            });
    };


    return (
        <div className="mobile-container">
            <h1 className="title-style">
                Mobile Number Page
            </h1>

            <MuiTelInput
                defaultCountry="IN"
                style={{ marginBottom: '20px' }}
                onlyCountries={['IN']}
                disableDropdown
                forceCallingCode
                value={mobileNumberDetail.mobileNumber}
                onChange={(value) => handleMobileNumberChange('mobileNumber')(value)}  // Pass the value directly
            />

            <Button
                variant="contained"
                color="primary"
                className="mobile-button"
                onClick={sendOTPRequest}
            >
                Send OTP
            </Button>
        </div>
    );
};

export default MobileNumber;