import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import InfoIcon from '@mui/icons-material/Info';
import {
    DataGrid,
    GridToolbarContainer,
    GridActionsCellItem
} from '@mui/x-data-grid';
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import AddCategoryFullScreenDialog from "./AddCategoryFullScreenDialog";
import EditCategoryFullScreenDialog from "./EditCategoryFullScreenDialog";
import {getAllCategories} from "../../../../Services/category.service";
import CategoryDetailsFullScreenDialog from "./CategoryDetailsFullScreenDialog";

function EditToolbar({handleAddCategoryDialogOpen}) {

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleAddCategoryDialogOpen}>
                Add Category
            </Button>
        </GridToolbarContainer>
    );
}

export default function AllCategoriesDataTable() {
    const [data, setData] = useState([]);
    const [isAddCategoryDialogOpen, setAddCategoryDialogOpen] = React.useState(false);
    const [isEditCategoryDialogOpen, setIsEditCategoryDialogOpen] = React.useState(false);
    const [isCategoryDetailsDialogOpen, setIsCategoryDetailsDialogOpen] = React.useState(false);
    const [categoryId, setCategoryId] = React.useState("");
    const [oldCategoryDetails, setOldCategoryDetails] = React.useState(null); // Added rowDetails state

    const handleAddCategoryDialogOpen = () => {
        setAddCategoryDialogOpen(true);
    };

    const handleIsCategoryDetailsDialogOpen = (row) => {
       setOldCategoryDetails(row)
        setIsCategoryDetailsDialogOpen(true);
    };

    const handleAddCategoryDialogClose = () => {
        setAddCategoryDialogOpen(false);
    };
    const handleEditCategoryDialogOpen = (id, row) => {
        toast.success(`Clicked id is ${id}`)
        toast.success(`Row is ${JSON.stringify(row)}`)
        setCategoryId(id)
        setOldCategoryDetails(row)
        setIsEditCategoryDialogOpen(true);
    };

    const handleEditCategoryDialogClickClose = () => {
        setIsEditCategoryDialogOpen(false);
    };

    const handleCategoryDetailsDialogClickClose = () => {
        setIsCategoryDetailsDialogOpen(false);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedData = await getAllCategories();
                setData(fetchedData["data"]);
                setRows(fetchedData["data"]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const [rows, setRows] = React.useState(data);
    const [rowModesModel, setRowModesModel] = React.useState({});

    const columns = [
        {
            field: 'categoryName',
            headerName: 'Category Name',
            width: 180,
            editable: false
        },
        {
            field: 'categoryImage',
            headerName: 'Category Image',
            width: 80,
            align: 'left',
            headerAlign: 'left',
            editable: false,
            renderCell: (params) => <img src={params.value}  alt={"category Image"}/>
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id, row }) => {
                return [
                    <GridActionsCellItem
                        icon={<InfoIcon />}
                        label="Details"
                        className="textPrimary"
                        onClick={() => handleIsCategoryDetailsDialogOpen(row)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => handleEditCategoryDialogOpen(id, row)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => handleEditCategoryDialogOpen(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 500,
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                slots={{
                    toolbar: () => <EditToolbar handleAddCategoryDialogOpen={handleAddCategoryDialogOpen} />
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
            />
            <AddCategoryFullScreenDialog
                isAddCategoryDialogOpen={isAddCategoryDialogOpen}
                handleAddCategoryDialogClose={handleAddCategoryDialogClose}
            />
            <EditCategoryFullScreenDialog
                categoryId = {categoryId}
                oldCategoryDetails = {oldCategoryDetails}
                isEditCategoryDialogOpen={isEditCategoryDialogOpen}
                handleEditCategoryDialogClickClose={handleEditCategoryDialogClickClose}
            />
            <CategoryDetailsFullScreenDialog
                oldCategoryDetails = {oldCategoryDetails}
                isCategoryDetailsDialogOpen={isCategoryDetailsDialogOpen}
                handleCategoryDetailsDialogClickClose={handleCategoryDetailsDialogClickClose}
            />
        </Box>
    );
}
