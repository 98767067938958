import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const ExpandableSubCategoryDataTable = ({ subCategories }) => {
    const columns = [
        {
            field: 'subCategoryName',
            headerName: 'Sub-Category Name',
            flex: 1
        },
        {
            field: 'subCategoryImage',
            headerName: 'Sub-Category Image',
            flex: 1,
            renderCell: (params) => <img src={params.value}  alt={"Sub-Category Image"}/>
        },
    ];

    return (
        <div style={{ height: 300, width: '100%' }}>
            <DataGrid
                rows={subCategories}
                columns={columns}
                pageSize={5}
                disableSelectionOnClick
            />
        </div>
    );
};

export default ExpandableSubCategoryDataTable;
